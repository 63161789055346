import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_404.module.scss"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: お探しのページは見つかりませんでした" />
    <div className="pageWrapper">
      <h1 className={styles.errorTtl}>404 PAGE NOT FOUND</h1>
      <h2 className={styles.errorSubMsg}>お探しのページは見つかりませんでした。</h2>
      <p>URLが間違っているか、ページが削除された可能性があります。</p>
      <p>ご不便をおかけいたしますが、URLをご確認いただくか、ReNK CHANNELトップから引き続きウェブサイトをお楽しみください。</p>
      <div className="btnWrapper">
        <div className="btnLarge">
          <a href="/">トップへ戻る
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
